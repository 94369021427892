import { waitAuth } from "~/utils/middleware";

import { PBEnums } from "~/types/pb/enums";

import { log } from "~/composables/log";

import Abonnements = PBEnums.Abonnements;

export default defineNuxtRouteMiddleware(async () => {
    await waitAuth(Abonnements.S3);

    if (!__subscription().hasS3) {
        log("❌ Has no S3");
        return navigateTo("/");
    }

    log("✅ Has S3");
});
